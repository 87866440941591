import React from 'react';
import ReactGA from 'react-ga';
import { RouteComponentProps } from 'react-router-dom';
import config from '../config/config';
import routes from '../config/routes';
import { useTherapySession } from '../contexts/TherapySessionContext';
import getQuestion from '../data/questions/getQuestion';
import usePauseMenu from '../hooks/usePauseMenu';
import AudioManager from '../lib/AudioManager';
import AppEnv from '../models/AppEnv.enum';
import IQuestion from '../models/question/IQuestion';
import SpeechResponse from '../models/SpeechResponse';
import ActiveQuestion from './ActiveQuestion';
import OnDeck from './OnDeck';
import TimeoutOverlay from './TimeoutOverlay';

const TherapySession: React.FC<RouteComponentProps<{ questionId: string }>> = ({ history, match }) => {
  React.useEffect(() => ReactGA.pageview(routes.Session), []);
  const debug = config.env === AppEnv.Dev;

  // Global contexts
  const { pause, replay } = AudioManager;
  const { therapySession, updatePath, prevId } = useTherapySession();
  const { params } = match;

  // Local state
  const [questions, setQuestions] = React.useState<Record<string, IQuestion>>({});
  const [repeat, setRepeat] = React.useState(0);
  const [showTimeoutMenu, setShowTimeoutMenu] = React.useState(false);
  const [experienceLevel, setExperienceLevel] = React.useState(0);

  const activeQuestion = questions[params.questionId];
  const targetYes = activeQuestion?.attributes.target_yes;
  const targetNo = activeQuestion?.attributes.target_no;
  const onDeckYes = questions[activeQuestion?.attributes.target_yes];
  const onDeckNo = questions[activeQuestion?.attributes.target_no];

  const fetchQuestion = React.useCallback(async (id: string) => {
    const res = await getQuestion(id);

    if (res?.data?.data) {
      setQuestions(qs => ({ ...qs, [res.data.data.id]: res.data.data }));
    }
  }, []);

  // Fetch question from URL if we don't have it
  React.useEffect(() => {
    if (!activeQuestion) {
      fetchQuestion(params.questionId);
    }
  }, [params.questionId, activeQuestion, fetchQuestion]);

  // Fetch question from current question's `target_yes` if we don't have it...
  React.useEffect(() => {
    if (targetYes && !onDeckYes) {
      fetchQuestion(targetYes);
    }
  }, [targetYes, onDeckYes, fetchQuestion]);

  // Fetch question from current question's `target_no` if we don't have it...
  React.useEffect(() => {
    if (targetNo && !onDeckNo) {
      fetchQuestion(targetNo);
    }
  }, [targetNo, onDeckNo, fetchQuestion]);

  React.useEffect(() => {
    if (!therapySession?.attributes.path.length && activeQuestion?.id) {
      updatePath(activeQuestion.id);
    }
  }, [therapySession?.attributes.path, activeQuestion?.id, updatePath]);

  const onAnswer = React.useCallback(
    (response: SpeechResponse) => {
      if (!(targetYes && targetNo)) {
        history.push(routes.Reorientation);
      }

      let experienceLvl = experienceLevel;

      setRepeat(0);

      let id: string;
      switch (response) {
        case SpeechResponse.Yes: {
          id = targetYes;
          break;
        }
        case SpeechResponse.No: {
          id = targetNo;
          break;
        }
        case SpeechResponse.Back: {
          id = prevId;
          break;
        }
        case SpeechResponse.GraduallyEnd: {
          id = config.session.graduallyEndId;
        }
      }

      // Experience Level hacksx
      // https://projects.marsworks.com/my-work?modal=Task-120354-1236

      // Reset at 1000 in case they loop around from 3000
      if (id === '1000') {
        experienceLvl = 0;
      }

      // Force start at 1079
      if (id === '1079') {
        experienceLvl = 1;
      }

      /**
       * User is "entering a new experience"
       *
       * The next ID is 1130
       */
      if (id === '1130') {
        experienceLvl++;
      }

      /**
       * User is "leaving an experience"
       *
       * Current question is 1221
       * Experience level 3 or above
       * User responsds "YES"
       * */
      if (activeQuestion.id === '1221' && experienceLevel >= 3 && response === SpeechResponse.Yes) {
        experienceLvl--;
        id = '1169';
      }
      //

      setExperienceLevel(experienceLvl);
      updatePath(id, response, experienceLvl);
      history.push(id);
    },
    [history, prevId, targetNo, targetYes, experienceLevel, activeQuestion, updatePath],
  );

  const onTimeout = React.useCallback(() => {
    if (repeat < config.session.maxQuestionRepeat) {
      setRepeat(r => r + 1);
    } else {
      setShowTimeoutMenu(true);
    }
  }, [repeat]);

  const onAudioEnd = React.useCallback(() => {
    if (!targetNo && !targetYes) {
      history.push(routes.Reorientation);
    }
  }, [targetNo, targetYes, history]);

  const { showPauseMenu, PauseMenu } = usePauseMenu({
    showResume: false,
    goBack: prevId
      ? () => {
          onAnswer(SpeechResponse.Back);
        }
      : undefined,
    endGradually: () => {
      onAnswer(SpeechResponse.GraduallyEnd);
    },
  });

  React.useEffect(() => {
    if (showPauseMenu) {
      setRepeat(0);
    }
  }, [pause, showPauseMenu]);

  return (
    <>
      <div className="justify-stretch align-stretch flex flex-col flex-1 text-center">
        {activeQuestion && !showPauseMenu && !showTimeoutMenu && (
          <>
            {debug && (
              <div className="bg-cool-gray-900 p-1 text-white">
                <div className="text-xs">experience level: {experienceLevel}</div>
                <div className="grid grid-cols-3 gap-2">
                  <button className="button button--secondary text-xs" onClick={() => history.push('1126')}>
                    Go to 1126
                  </button>
                  <button className="button button--secondary text-xs" onClick={() => history.push('1221')}>
                    Go to 1221
                  </button>

                  <button className="button button--secondary text-xs" onClick={() => history.push('1192')}>
                    Go to 1192
                  </button>
                </div>
              </div>
            )}
            <ActiveQuestion
              id={activeQuestion.id}
              audioSrc={activeQuestion.attributes.audio}
              text={activeQuestion.attributes.text}
              timeout={config.session.questionTimeout}
              repeat={repeat}
              onAnswer={onAnswer}
              onTimeout={onTimeout}
              onAudioEnd={onAudioEnd}
              showButtons={!!targetNo && !!targetYes}
            />
          </>
        )}

        <div className="hidden">
          <div>{onDeckYes && <OnDeck question={onDeckYes} />}</div>
          <div>{onDeckNo && <OnDeck question={onDeckNo} />}</div>
        </div>
      </div>
      <PauseMenu />
      {showTimeoutMenu && (
        <TimeoutOverlay
          continueSession={() => {
            setRepeat(0);
            setShowTimeoutMenu(false);
            replay();
          }}
        />
      )}
    </>
  );
};

export default React.memo(TherapySession);
