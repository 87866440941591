/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import IQuestion from '../models/question/IQuestion';

interface Props {
  question: IQuestion;
}

const OnDeck: React.FC<Props> = ({ question }) => {
  return (
    <>
      <audio src={question.attributes.audio} />
    </>
  );
};

export default OnDeck;
