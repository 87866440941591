import { Howler, Howl, HowlOptions } from 'howler';

/**
 * @todo make this a hook?
 */

// Howler.autoSuspend = false;

let AUDIO_MANAGER: Howl;

function getAudioManager(): Howl {
  if (!AUDIO_MANAGER) {
    AUDIO_MANAGER = new Howl({ src: [''] });
  }

  return AUDIO_MANAGER;
}

function createAudio(options: HowlOptions): Howl {
  if (AUDIO_MANAGER) {
    AUDIO_MANAGER.unload();
  }

  AUDIO_MANAGER = new Howl({
    html5: true,
    autoplay: true,
    ...options,
  });

  return AUDIO_MANAGER;
}

function play(): void {
  const audioManager = getAudioManager();

  if (!audioManager.playing()) {
    audioManager.play();
  }
}

function pause(): void {
  getAudioManager().pause();
}

function stop(): void {
  getAudioManager().stop();
}

function replay(): void {
  const audioManager = getAudioManager();
  audioManager.stop();
  audioManager.play();
}

const AudioManager = {
  getAudioManager,
  createAudio,
  play,
  pause,
  stop,
  replay,
};

export default AudioManager;
