import React from 'react';
import config from '../config/config';

interface Props {
  resume: (() => void) | undefined;
  replay: () => void;
  goBack?: () => void;
  endGradually?: () => void;
}

const PauseOverlay: React.FC<Props> = ({ resume, replay, goBack, endGradually }) => {
  return (
    <div className="bg-tan-med scroll-y-auto justify-stretch absolute inset-0 flex flex-col flex-1 max-w-screen-md min-h-full mx-auto">
      <header className="pt-8 pb-2 text-center">
        <h1 className="h1 mx-auto mb-2">Paused</h1>
      </header>
      <div className="flex flex-col items-stretch justify-center p-4 space-y-6 text-center">
        <button type="button" className="button button--primary block text-2xl font-semibold" onClick={replay}>
          Repeat Current Question
        </button>
        {resume && (
          <button type="button" className="button button--primary block text-2xl font-semibold" onClick={resume}>
            Resume
          </button>
        )}
        {goBack && (
          <button type="button" className="button button--primary block text-2xl font-semibold" onClick={goBack}>
            Go Back to Previous Question
          </button>
        )}
        {endGradually && (
          <button type="button" className="button button--primary block text-2xl font-semibold" onClick={endGradually}>
            End Session Gradually
          </button>
        )}

        <a href={config.api.root} className="button button--primary block text-2xl font-semibold">
          Exit session
        </a>
      </div>
    </div>
  );
};

export default PauseOverlay;
